import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { ResultsGrid } from '../components/TeaserGrids/index.js';
import { LatestPostsData } from '../hooks/latestPosts';
import Categories from '../components/Categories/Categories';

const NotFoundPage = () => {


	const featuredPosts = LatestPostsData();
	const browser = typeof window !== "undefined" && window;

	return (
		browser && (

			<Layout noPage title="Uh oh!">
			<SEO title="404: Not found" />
			<ResultsGrid noPage posts={featuredPosts.posts.nodes.slice(0, 6)} />
			<Categories title="Browse our categories" browser />
		</Layout>
		)
	);
};

export default NotFoundPage;
